<template>
  <v-container class="py-12">
    <v-row>
      <v-col class="text-center primary--text pt-12">
        <h2>{{ $t("projects.PROJECTS") }}</h2>
      </v-col>
    </v-row>
    <v-row class="py-6">
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=Assyriaphysicaleducation&317582463_215699174183991_2238404857725631665_n.jpg'"
        >
          <img
            src="../../assets/317582463_215699174183991_2238404857725631665_n.jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.Assyriaphysicaleducation") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=BodourResidentialCompoundBaghdad&Picture5.jpg'"
        >
          <img src="../../assets/Picture5.jpg" width="100%" height="300px" />
          <h4 class="">
            {{ $t("projects.BodourResidentialCompoundBaghdad") }}
          </h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=TheBowlingProject&Picture8.jpg'"
        >
          <img src="../../assets/Picture8.jpg" width="100%" height="300px" />
          <h4 class="">{{ $t("projects.TheBowlingProject") }}</h4>
        </v-card>
      </v-col>

      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=displayScreen&326730789_849555039641527_8895169872616020214_n.jpg'"
        >
          <img
            src="../../assets/326730789_849555039641527_8895169872616020214_n.jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.displayScreen") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=AlSalamResidentialCompound&photo_alsalam.jpg'"
        >
          <img
            src="../../assets/photo_alsalam.jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.AlSalamResidentialCompound") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=Shopping Center&a1 wazerya.jpg'"
        >
          <img src="../../assets/a1 wazerya.jpg" width="100%" height="300px" />
          <h4 class="">{{ $t("projects.ShoppingCenter") }}</h4>
        </v-card>
      </v-col>

      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=HematologyCenterMedicalCity&Hospital1.jpg'"
        >
          <img src="../../assets/Hospital1.jpg" width="100%" height="300px" />
          <h4 class="">{{ $t("projects.HematologyCenterMedicalCity") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=fiberCable&320758278_1297047871059537_576269721118698138_n.jpg'"
        >
          <img
            src="../../assets/320758278_1297047871059537_576269721118698138_n.jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.fiberCable") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=SouthernLinkProjectHolyKarbala&Picture4444.jpg'"
        >
          <img src="../../assets/Picture4444.jpg" width="100%" height="300px" />
          <h4 class="">{{ $t("projects.SouthernLinkProjectHolyKarbala") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=Universities&is8.jpg'"
        >
          <img src="../../assets/is8.jpg" width="100%" height="300px" />
          <h4 class="">{{ $t("projects.Universities") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=AssyriaUniversity&328297121_496032832606368_7992446374621410368_n.jpg'"
        >
          <img
            src="../../assets/328297121_496032832606368_7992446374621410368_n.jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.AssyriaUniversity") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=Firefighting&316555388_214842860936289_7695332466732528801_n.jpg'"
        >
          <img
            src="../../assets/316555388_214842860936289_7695332466732528801_n.jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.Firefighting") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=electricity&electricty.jpg'"
        >
          <img src="../../assets/electricty.jpg" width="100%" height="300px" />
          <h4 class="">{{ $t("projects.electricity") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=BabylonMall&PAGE_PROG 12 copy.jpg'"
        >
          <img
            src="../../assets/PAGE_PROG 12 copy.jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.BabylonMall") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=electricityAlIsraa&317846792_215699260850649_7278130778668414900_n.jpg'"
        >
          <img
            src="../../assets/317846792_215699260850649_7278130778668414900_n.jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.electricityAlIsraa") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=AssyriaCollegeLightingProject&332134082_546290704263169_8037828205956785488_n.jpg'"
        >
          <img
            src="../../assets/332134082_546290704263169_8037828205956785488_n.jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.AssyriaCollegeLightingProject") }}</h4>
        </v-card>
      </v-col>
      <v-col class="px-4 pb-8" cols="12" lg="4" md="6" sm="12">
        <v-card
          flat
          class=""
          height="320"
          width="100%"
          :to="'/project=PhantomLab&photo_2023-03-29_23-18-15 (2).jpg'"
        >
          <img
            src="../../assets/photo_2023-03-29_23-18-15 (2).jpg"
            width="100%"
            height="300px"
          />
          <h4 class="">{{ $t("projects.PhantomLab") }}</h4>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    // openProject(title,imgsrc){
    // }
  },
};
</script>

<style></style>
